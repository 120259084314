import { RouteHref } from '@/types/routes'

export function getPathString(href: RouteHref): string {
  if (typeof href === 'string') {
    return href
  } else {
    const { pathname } = href
    const params: Record<string, unknown> = href.params ?? {}

    let locale = ''
    if (typeof params.locale === 'string') {
      locale = `/${params.locale}`
    }

    let path = `${locale}${pathname}`

    // Replace dynamic segments with params
    Object.keys(params).forEach((key) => {
      if (key !== 'locale') {
        const value = params[key]
        path = path.replace(`[${key}]`, encodeURIComponent(String(value)))
      }
    })

    // Construct query string
    let queryString = ''
    if ('searchParams' in href && href.searchParams) {
      const searchParams = href.searchParams
      if (Object.keys(searchParams).length > 0) {
        const query = new URLSearchParams()
        Object.entries(searchParams).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value))
          }
        })
        queryString = `?${query.toString()}`
      }
    }

    return `${path}${queryString}`
  }
}
