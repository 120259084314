import(/* webpackMode: "eager" */ "/builds/opptak/admissio-soknadsbehandling/node_modules/@sikt/sds-logo/Favicon-Light.svg");
;
import(/* webpackMode: "eager" */ "/builds/opptak/admissio-soknadsbehandling/node_modules/@sikt/sds-logo/Favicon-Light@180px.png");
;
import(/* webpackMode: "eager" */ "/builds/opptak/admissio-soknadsbehandling/node_modules/@sikt/sds-logo/Favicon-Light@32px.png");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/builds/opptak/admissio-soknadsbehandling/node_modules/next-auth/react.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/opptak/admissio-soknadsbehandling/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/admissio-soknadsbehandling/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/builds/opptak/admissio-soknadsbehandling/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/builds/opptak/admissio-soknadsbehandling/src/app/layout.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["CommandPalette"] */ "/builds/opptak/admissio-soknadsbehandling/src/components/CommandPalette/CommandPalette.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationEvents"] */ "/builds/opptak/admissio-soknadsbehandling/src/components/NavigationEvents/NavigationEvents.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionTimeout"] */ "/builds/opptak/admissio-soknadsbehandling/src/components/SessionTimeout/SessionTimeout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Snackbar"] */ "/builds/opptak/admissio-soknadsbehandling/src/components/Snackbar/Snackbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Header"] */ "/builds/opptak/admissio-soknadsbehandling/src/features/login/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloProvider"] */ "/builds/opptak/admissio-soknadsbehandling/src/lib/apollo/apollo-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/builds/opptak/admissio-soknadsbehandling/src/lib/auth/userContext.tsx");
