import { CSSProperties, ElementType, ReactNode } from 'react'

import tokens from '@sikt/sds-tokens/dist/js/tokens'
import clsx from 'clsx'

import styles from './Flex.module.css'

type Gap = keyof typeof tokens.space.gap

interface FlexProps {
  alignItems?: CSSProperties['alignItems']
  children: ReactNode
  className?: string
  component?: ElementType
  direction?: CSSProperties['flexDirection']
  gap?: Gap | 0
  gapColumn?: Gap | 0
  gapRow?: Gap | 0
  justifyContent?:
    | 'center'
    | 'flex-end'
    | 'flex-start'
    | 'space-around'
    | 'space-between'
    | 'space-evenly'
  flexWrap?: CSSProperties['flexWrap']
  fullWidth?: boolean
  flexGrow?: 0 | 1 | 2
}

export const Flex = ({
  alignItems = 'stretch',
  children,
  className,
  component: Component = 'div',
  direction = 'row',
  gap = 0,
  gapColumn,
  gapRow,
  justifyContent = 'flex-start',
  flexWrap = 'nowrap',
  fullWidth = false,
  flexGrow = 0,
  ...rest
}: FlexProps) => {
  return (
    <Component
      className={clsx(
        styles.flex,
        className,
        gap && styles[`gap-${gap}`],
        gapColumn && styles[`gapColumn-${gapColumn}`],
        gapRow && styles[`gapRow-${gapRow}`],
        alignItems && styles[`align-${alignItems}`],
        styles[`flex-${direction}`],
        styles[`justify-${justifyContent}`],
        styles[`wrap-${flexWrap}`],
        flexGrow && styles[`grow-${flexGrow}`],
        fullWidth && styles.fullWidth,
      )}
      {...rest}
    >
      {children}
    </Component>
  )
}

export default Flex
