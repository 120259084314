import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

import { SeverityType } from '../Alert/Alert'

interface BaseSnackbar {
  message: string
  severity?: SeverityType
  id: string
  duration?: number // Duration in milliseconds; 0 means stay open until closed
}

interface SnackbarWithActionFunction extends BaseSnackbar {
  actionFunction: (close: () => void) => void
  actionFunctionLabel: string
  actionFunctionClassname?: string
}

interface SnackbarWithNoActionFunction extends BaseSnackbar {
  actionFunction?: never
  actionFunctionLabel?: never
  actionFunctionClassname?: never
}

export type Snackbar = SnackbarWithActionFunction | SnackbarWithNoActionFunction

interface SnackbarStore {
  snackbars: Snackbar[]
}

type AddSnackbarParams =
  | Omit<SnackbarWithActionFunction, 'id'>
  | Omit<SnackbarWithNoActionFunction, 'id'>

interface Actions {
  addSnackbar: (snackbar: AddSnackbarParams) => string
  closeSnackbar: (id: string) => void
  setSnackbars: (snackbars: Snackbar[]) => void
}

export const useSnackbar = create(
  immer<SnackbarStore & Actions>((set) => ({
    snackbars: [],
    addSnackbar: (snackbar) => {
      const id = uuidv4()
      snackbar.duration ??= 6000
      set((state: SnackbarStore) => {
        state.snackbars.push({ ...snackbar, id })
      })
      return id
    },
    closeSnackbar: (id: string) => {
      set((state: SnackbarStore) => {
        state.snackbars = state.snackbars.filter((snackbar) => snackbar.id !== id)
      })
    },
    setSnackbars: (snackbars) => {
      set((state: SnackbarStore) => {
        state.snackbars = snackbars
      })
    },
  })),
)
