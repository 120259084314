'use client'
import { ReactNode } from 'react'

import { CheckCircle } from '@phosphor-icons/react'
import { Button } from '@sikt/sds-button'
import { Paragraph } from '@sikt/sds-core'
import { InfoIcon, WarningIcon } from '@sikt/sds-icons'
import clsx from 'clsx'

import styles from './Alert.module.css'

export type SeverityType = 'error' | 'warning' | 'info' | 'success'

interface AlertBaseProps {
  message: string
  icon?: ReactNode | null
  severity?: SeverityType
  className?: string
}

interface AlertPropsWithActionFunctions extends AlertBaseProps {
  onAction: () => void
  actionButtonLabel: string
  actionButtonClassname?: string
}

interface AlertPropsWithNoActionFunctions extends AlertBaseProps {
  onAction?: never
  actionButtonLabel?: never
  actionButtonClassname?: never
}

interface CloseButtonProps {
  onClose: () => void
  closeButtonLabel: string
}

interface HideCloseButtonProps {
  onClose?: never
  closeButtonLabel?: never
}

type AlertProps = (AlertPropsWithActionFunctions | AlertPropsWithNoActionFunctions) &
  (CloseButtonProps | HideCloseButtonProps)

export function Alert({
  message,
  icon,
  severity = 'info',
  className,
  onClose,
  closeButtonLabel,
  onAction,
  actionButtonLabel,
  actionButtonClassname,
  ...rest
}: AlertProps) {
  return (
    <div {...rest} className={clsx(styles.alert, styles[`alert--${severity}`], className)}>
      <div>{icon ? icon : severityIconMapping[severity]}</div>
      <div className={styles['alert--paragraph']}>
        <Paragraph variant="regular">{message}</Paragraph>
      </div>
      <div className={styles['alert--buttons']}>
        {onAction && (
          <Button
            size="small"
            aria-label={actionButtonLabel}
            className={actionButtonClassname}
            onClick={() => {
              onAction()
            }}
          >
            {actionButtonLabel}
          </Button>
        )}
        {onClose && (
          <div>
            <Button
              size="small"
              aria-label={closeButtonLabel}
              onClick={() => {
                onClose()
              }}
            >
              {closeButtonLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

const severityIconMapping: Record<SeverityType, ReactNode> = {
  error: <WarningIcon fontSize={32} />,
  warning: <WarningIcon fontSize={32} />,
  info: <InfoIcon fontSize={32} />,
  success: <CheckCircle fontSize={32} />,
}
