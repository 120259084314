'use client'

import { ElementType, ReactNode } from 'react'

import tokens from '@sikt/sds-tokens'
import clsx from 'clsx'

import styles from './Surface.module.css'

type Color = keyof typeof tokens.color.layout.background
type Padding = keyof typeof tokens.space.padding
type Radius = keyof typeof tokens.space.border.radius
type Shadow = keyof typeof tokens.effect.shadow

interface FooterProps {
  padding?: Padding | 'medium1' //TODO: Remove when implemented in designsystem
  color?: Color
  border?: boolean
}

interface SurfaceProps {
  backgroundColor?: Color
  borderRadius?: Radius
  children: ReactNode
  classname?: string
  component?: ElementType
  fullWidth?: boolean
  padding?: Padding | 'medium1' //TODO: Remove when implemented in designsystem
  paddingHorizontal?: Padding | 'medium1' //TODO: Remove when implemented in designsystem
  shadow?: Shadow
  border?: boolean
  footer?: ReactNode
  footerProps?: FooterProps
}

export function Surface({
  backgroundColor = 'default',
  borderRadius = 'medium',
  children,
  classname,
  component: Component = 'div',
  fullWidth = true,
  padding = 'medium',
  paddingHorizontal,
  shadow,
  border,
  footer,
  footerProps = {},
  ...rest
}: SurfaceProps) {
  const {
    padding: footerPadding = 'small',
    color = 'primary',
    border: footerBorder = true,
  } = footerProps
  return (
    <div className={styles.surfaceWrapper}>
      <Component
        className={clsx(
          styles.surface,
          classname,
          styles[`backgroundColor-${backgroundColor}`],
          styles[`borderRadius-${borderRadius}`],
          styles[`padding-${padding}`],
          styles[`paddingHorizontal-${paddingHorizontal}`],
          styles[`shadow-${shadow}`],
          border && styles.border,
          fullWidth && styles.fullWidth,
        )}
        {...rest}
      >
        {children}
      </Component>
      {footer && (
        <div
          className={clsx(
            styles.surfaceFooter,
            fullWidth && styles.fullwidth,
            styles[`shadow-${shadow}`],
            styles[`borderRadius-${borderRadius}`],
            styles[`paddingVertical-${footerPadding}`],
            styles[`paddingHorizontal-${paddingHorizontal ?? padding}`],
            styles[`backgroundColor-${color}`],
            footerBorder && styles.border,
          )}
        >
          {footer}
        </div>
      )}
    </div>
  )
}
