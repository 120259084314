'use client'

import { useEffect } from 'react'

import { usePathname, useSearchParams } from 'next/navigation'

export function NavigationEvents() {
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    const _paq = (window._paq = window._paq ?? [])
    _paq.push(['setCustomUrl', `${pathname}?${searchParams.toString()}`])
    _paq.push(['setDocumentTitle', document.title])
    _paq.push(['trackPageView'])
  }, [pathname, searchParams])

  return null
}
