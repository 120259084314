import { ReactNode } from 'react'

import { DrawerButtonLink, DrawerButtonLinkProps } from '@sikt/sds-drawer'
// eslint-disable-next-line no-restricted-imports
import { LinkProps } from 'next/link'

import { TypedLinkWrapper } from '@/components/TypedLinkWrapper/TypedLinkWrapper'
import { RouteHref } from '@/types/routes'

import styles from './AppDrawerLink.module.css'

interface AppDrawerLinkProps extends Omit<DrawerButtonLinkProps, 'href'> {
  href: RouteHref
  icon: ReactNode
  nextLinkProps?: Omit<LinkProps, 'href'>
}

export function AppDrawerLink({ href, children, icon, nextLinkProps }: AppDrawerLinkProps) {
  return (
    <TypedLinkWrapper legacyBehavior passHref href={href} {...nextLinkProps}>
      <DrawerButtonLink className={styles.appDrawerlink} icon={icon}>
        {children}
      </DrawerButtonLink>
    </TypedLinkWrapper>
  )
}
