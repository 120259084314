'use client'

import { useEffect, useState } from 'react'

import { Modal } from '@sikt/sds-modal'
import { usePathname } from 'next/navigation'
import { signOut, useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { SignInButton } from '@/features/login/SignInButton/SignInButton'
import { SignOutButton } from '@/features/login/Signoutbutton/SignOutButton'
import { useTypedRouter } from '@/hooks/useTypedRouter/useTypedRouter'

export function SessionTimeout() {
  const [showModal, setShowModal] = useState(false)
  const { data: session } = useSession()
  const pathname = usePathname()
  const router = useTypedRouter()
  const t = useTranslations('common.SessionTimeout')
  const intervalDelayMinutes = 10
  const intervalDelay = intervalDelayMinutes * 60 * 1000

  useEffect(() => {
    const interval = setInterval(() => {
      const isLoggedIn = session

      if (isLoggedIn) {
        const now = Date.now()
        const expires = new Date(session.expires).getTime()
        const isSessionExpired = expires < now
        const isSessionAboutToExpire = expires - intervalDelay < now

        if (isSessionExpired) {
          clearInterval(interval)
          signOut({
            callbackUrl: pathname,
          }).catch(() => {
            console.error('Session timeout: Failed to sign out user')
          })
        } else if (isSessionAboutToExpire) {
          const appElement = document.body
          Modal.setAppElement(appElement)
          setShowModal(true)
        }
      } else {
        clearInterval(interval)
      }
    }, intervalDelay)

    return () => {
      clearInterval(interval)
    }
  }, [session, pathname, router, intervalDelay])

  return (
    showModal && (
      <Modal
        heading={t('heading')}
        footer={
          <>
            <SignOutButton />
            <SignInButton callbackUrl={pathname} />
          </>
        }
        onClose={() => {
          setShowModal(false)
        }}
        closeButtonLabel={t('close')}
        open
      >
        {t('body', { tid: intervalDelayMinutes })}
      </Modal>
    )
  )
}
