'use client'

import { PropsWithChildren } from 'react'

import { ApolloLink, HttpLink } from '@apollo/client'
import {
  ApolloClient,
  ApolloNextAppProvider,
  InMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support'
import { useSession } from 'next-auth/react'

import { cacheConfig } from './cacheConfig'

function makeClient() {
  const httpLink = new HttpLink({
    uri: '/api/graphql',
  })

  return new ApolloClient({
    cache: new InMemoryCache(cacheConfig),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([
            new SSRMultipartLink({
              stripDefer: true,
            }),
            httpLink,
          ])
        : httpLink,
  })
}

export function ApolloProvider({ children }: PropsWithChildren) {
  const { status } = useSession()

  return (
    status !== 'loading' && (
      <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>
    )
  )
}
