'use client'

import { useState, useEffect } from 'react'

import { List, ListChecks, Scales, Student, UserCircle, X, House } from '@phosphor-icons/react'
import { Button } from '@sikt/sds-button'
import { UserCircleIcon } from '@sikt/sds-icons'
import { usePathname } from 'next/navigation'
import { Session } from 'next-auth'
import { useSession } from 'next-auth/react'
import { useTranslations } from 'next-intl'

import { Personas } from '../Personas/Personas'
import { AppDrawerLink } from '@/components/AppDrawerLink/AppDrawerLink'
import Flex from '@/components/Flex/Flex'
import { Link } from '@/components/Link'
import { SignInButton } from '@/features/login/SignInButton/SignInButton'
import { SignOutButton } from '@/features/login/Signoutbutton/SignOutButton'
import { useUserContext } from '@/lib/auth/userContext'

import styles from './header.module.css'

const SignInOutButton = ({ session }: { session: Session | null }) =>
  session ? (
    <SignOutButton variant="transparent" className={styles.signOut} />
  ) : (
    <SignInButton variant="transparent" shortLabel />
  )

export const Header = () => {
  const tHeader = useTranslations('login.Header')
  const t = useTranslations('components.AppDrawer')
  const { data: session, status } = useSession()
  const [menuExpanded, setMenuExpanded] = useState(false)
  const { userInfo } = useUserContext()

  const toggleMenu = () => {
    setMenuExpanded((prev) => !prev)
  }
  const path = usePathname()

  useEffect(() => {
    setMenuExpanded(false)
  }, [path])

  return (
    <header className={styles.headerWrapper} role="banner">
      <Flex className={styles.header} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          {status === 'authenticated' && (
            <>
              <div className={styles.menu}>
                <Button
                  variant="transparent"
                  iconVariant="left"
                  icon={menuExpanded ? <X /> : <List />}
                  onClick={toggleMenu}
                  className={styles.menu}
                >
                  <span className={styles.fixedWidthText}>
                    {menuExpanded ? tHeader('close') : tHeader('meny')}
                  </span>
                </Button>
              </div>
              <span className={styles.divider} />
            </>
          )}
          <Link className={styles.logo} aria-current="page" href="/">
            <span>{tHeader('logo')}</span>
          </Link>
        </Flex>
        <Flex alignItems="center" gap="medium">
          <UserCircleIcon fontSize={32} />
          {userInfo ? (
            <Flex direction="column">
              <span className={styles.username}>{`${userInfo.fornavn} ${userInfo.etternavn}`}</span>
              <span
                className={styles.userSubtitle}
              >{`${userInfo.rollekode}, ${userInfo.navn}`}</span>
            </Flex>
          ) : (
            <span>{t('noPersonasSelected')}</span>
          )}
        </Flex>
      </Flex>
      {menuExpanded && (
        <>
          <div
            className={styles.overlay}
            role="none"
            onClick={() => {
              setMenuExpanded(false)
            }}
          />
          <nav
            aria-label={tHeader('menuDropdown')}
            role="navigation"
            className={styles.dropdownMenu}
          >
            <div className={styles.menuItems}>
              <AppDrawerLink href="/" icon={<House size={32} />}>
                {t('home')}
              </AppDrawerLink>
              <AppDrawerLink href="/opptak" icon={<Student size={32} />}>
                {t('opptak')}
              </AppDrawerLink>
              <AppDrawerLink href="/regelverk" icon={<Scales size={32} />}>
                {t('regelverk')}
              </AppDrawerLink>
              <AppDrawerLink href="/soknadsbehandling" icon={<ListChecks size={32} />}>
                {t('soknadsbehandling')}
              </AppDrawerLink>
              <AppDrawerLink href="/personer" icon={<UserCircle size={32} />}>
                {t('personer')}
              </AppDrawerLink>
              <Personas />
            </div>
            {status !== 'loading' ? <SignInOutButton session={session} /> : null}
          </nav>
        </>
      )}
    </header>
  )
}
