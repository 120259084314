/* eslint-disable @typescript-eslint/require-await */
'use server'
import { revalidatePath } from 'next/cache'
import { cookies } from 'next/headers'

import { UserInformation } from './userContext'

const USER_INFO_COOOKIE_NAME = 'user_info'

export async function setUserCookie(userInfo: UserInformation | undefined) {
  const cookieStorage = cookies()

  if (userInfo) {
    cookieStorage.set(USER_INFO_COOOKIE_NAME, JSON.stringify(userInfo))
  } else {
    cookieStorage.delete(USER_INFO_COOOKIE_NAME)
  }

  revalidatePath('/', 'layout')

  return Promise.resolve()
}

export async function getUserCookie(): Promise<UserInformation | undefined> {
  const cookieStorage = cookies()

  const userInfoValue = cookieStorage.get(USER_INFO_COOOKIE_NAME)?.value
  const parsedUserInfoValue = userInfoValue ? (JSON.parse(userInfoValue) as unknown) : undefined

  return isUserInformation(parsedUserInfoValue) ? parsedUserInfoValue : undefined
}

export const isUserInformation = (value: unknown): value is UserInformation =>
  typeof value === 'object' &&
  value !== null &&
  'fornavn' in value &&
  'etternavn' in value &&
  'organisasjonsnummer' in value &&
  'beskrivelse' in value &&
  'identifikator' in value &&
  'navn' in value &&
  'rollekode' in value
