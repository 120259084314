import {
  AppRouterInstance,
  NavigateOptions,
} from 'next/dist/shared/lib/app-router-context.shared-runtime'
import { PrefetchOptions } from 'next/dist/shared/lib/router/router'
// eslint-disable-next-line no-restricted-imports
import { useRouter as useNextRouter } from 'next/navigation'

import { RouteHref } from '@/types/routes'
import { getPathString } from '@/utils/routeUtils'

export interface TypedRouter extends Omit<AppRouterInstance, 'push' | 'replace' | 'prefetch'> {
  push(url: RouteHref, options?: NavigateOptions): void
  replace(url: RouteHref, options?: NavigateOptions): void
  prefetch(url: RouteHref, options?: PrefetchOptions): void
}

export function useTypedRouter(): TypedRouter {
  const router = useNextRouter()

  const push: TypedRouter['push'] = (url, options) => {
    const formattedUrl = getPathString(url)
    router.push(formattedUrl, options)
  }

  const replace: TypedRouter['replace'] = (url, options) => {
    const formattedUrl = getPathString(url)
    router.replace(formattedUrl, options)
  }

  const prefetch: TypedRouter['prefetch'] = (url) => {
    const formattedUrl = getPathString(url)
    router.prefetch(formattedUrl)
  }

  return {
    ...router,
    push,
    replace,
    prefetch,
  }
}
